import Vue from "vue";
import App from "./App.vue";
import router from "./ui/router";
import store from "./ui/store";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowCircleDown,
  faArrowDown,
  faArrowLeft,
  faArrowUp,
  faBan,
  faBuilding,
  faCalendar,
  faCalendarCheck,
  faCalendarDay,
  faCalendarPlus,
  faCalendarTimes,
  faCar,
  faCarCrash,
  faCaretDown,
  faCaretSquareDown,
  faCaretSquareRight,
  faCaretSquareUp,
  faCaretUp,
  faChartLine,
  faCheck,
  faCheckDouble,
  faChevronUp,
  faCircle,
  faClipboardList,
  faCog,
  faComments,
  faEllipsisH,
  faEraser,
  faExclamationCircle,
  faExclamationTriangle,
  faEye,
  faFile,
  faFileDownload,
  faFilePdf,
  faFilter,
  faGavel,
  faHistory,
  faLocationArrow,
  faMagic,
  faPaperPlane,
  faPen,
  faPlus,
  faPlusCircle,
  faQuestionCircle,
  faSave,
  faSearch,
  faShareSquare,
  faSignOutAlt,
  faSortAmountDownAlt,
  faSortAmountUpAlt,
  faStar,
  faSync,
  faTag,
  faTools,
  faTrashAlt,
  faUndo,
  faUpload,
  faUser,
  faUsers,
  faUserSlash,
  faWindowClose,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import VueApexCharts from "vue-apexcharts";
import VueKeyCloak from "@dsb-norge/vue-keycloak-js";
import moment from "moment";
import i18n from "@/ui/i18n";
import { userModuleMeta } from "@/ui/store/user";
import { configModuleMeta } from "@/ui/store/configuration";
import theme_switcher from "@/common/service/ThemeSwitcher";
import { UserHttpClient } from "@/common/http";

library.add(
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowCircleDown,
  faArrowDown,
  faArrowLeft,
  faArrowUp,
  faBan,
  faBuilding,
  faCalendar,
  faCalendarCheck,
  faCalendarDay,
  faCalendarPlus,
  faCalendarTimes,
  faCar,
  faCarCrash,
  faCaretDown,
  faCaretSquareDown,
  faCaretSquareRight,
  faCaretSquareUp,
  faCaretUp,
  faChartLine,
  faCheck,
  faCheckDouble,
  faChevronUp,
  faCircle,
  faClipboardList,
  faCog,
  faComments,
  faEllipsisH,
  faEraser,
  faExclamationCircle,
  faExclamationTriangle,
  faEye,
  faFile,
  faFileDownload,
  faFilePdf,
  faFilter,
  faGavel,
  faHistory,
  faLocationArrow,
  faMagic,
  faPaperPlane,
  faPen,
  faPlus,
  faPlusCircle,
  faQuestionCircle,
  faSave,
  faSearch,
  faShareSquare,
  faSignOutAlt,
  faSortAmountDownAlt,
  faSortAmountUpAlt,
  faStar,
  faSync,
  faTag,
  faTools,
  faTrashAlt,
  faUndo,
  faUpload,
  faUser,
  faUsers,
  faUserSlash,
  faWindowClose,
  faTimesCircle
);

Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);
Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

Vue.filter("relativeDate", function (value: Date) {
  if (value) {
    return moment(value).locale("de").fromNow();
  }
});

Vue.filter("fullDateAndTime", function (value: Date) {
  if (value) {
    return i18n.d(value, "long", "de-DE");
  }
});

Vue.filter("fullDate", function (value: Date) {
  if (value !== null) {
    return i18n.d(value, "sort", "de-DE");
  }
});

Vue.filter("emptyMessage", function (value: string) {
  if (value) {
    return value;
  } else {
    return i18n.t("UI.message.notAvailable");
  }
});

Vue.filter("currency", function (value: number) {
  if (!value) {
    value = 0;
  }
  const option = {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };
  const formatter = new Intl.NumberFormat("de-DE", option);
  return formatter.format(value);
});

Vue.filter("percentage", function (value: number) {
  if (!value) {
    value = 0;
  }
  const option = {
    style: "percent",
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  };
  const formatter = new Intl.NumberFormat("de-DE", option);
  return formatter.format(value / 100);
});

Vue.filter("rawPercentage", function (value: number) {
  if (!value) {
    value = 0;
  }
  const option = {
    style: "percent",
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  };
  const formatter = new Intl.NumberFormat("de-DE", option);
  return formatter.format(value);
});

Vue.filter("trim", function (value: string) {
  if (value) {
    return value.trim();
  } else {
    return value;
  }
});

Vue.filter("firstUpper", function (value: string) {
  if (value) {
    return value.charAt(0).toUpperCase() + value.slice(1);
  } else {
    return value;
  }
});

Vue.filter("shortFilename", function (value: string, width: number) {
  const fontSize = 12;
  const threshold = Math.max(Math.floor(width / fontSize) - 35, 10);

  if (value) {
    const lastIndex = value.lastIndexOf(".");
    if (lastIndex > threshold) {
      const fileName = value.substring(0, threshold);
      const ext = value.substring(lastIndex);

      return fileName + ".." + ext;
    }

    return value;
  }
});

export const event_bus = new Vue();

Vue.use(VueKeyCloak, {
  config: {
    url: store.getters[configModuleMeta.getters.getKeycloakUrl],
    realm: store.getters[configModuleMeta.getters.getKeycloakRealm],
    clientId: store.getters[configModuleMeta.getters.getKeycloakClientId],
    cors: true,
  },
  onReady: async () => {
    const details = await new UserHttpClient().getDetails();
    const firstLocation = Array.from(details.locations)[0];
    const theme_candidate = firstLocation.locationCode.split("/")[0];
    const theme = theme_switcher.isThemeAvailable(theme_candidate)
      ? theme_candidate
      : "linkki";

    store.commit(userModuleMeta.mutations.setDetails, details);
    store.commit(configModuleMeta.mutations.setTheme, theme);

    new Vue({
      router,
      store,
      i18n,
      render: (h) => h(App),
    }).$mount("#app");
  },
});
